.SlideShow_Video {
    width: 100%;
    height: 540px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;

    overflow: hidden;
    position: relative;
}

.SlideShow_Video .container{
    width: calc(100% - 40px);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.SlideShow_Video .show_img .title{
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: 'reforma1918_700', sans-serif;
    font-size: 52px;
    line-height: normal;
    font-weight: 600;

    position: relative;
    color: transparent; 
    -webkit-text-stroke: 0px 0px #ffffff;
    text-transform: uppercase;
    white-space: nowrap;
    transition: 0.5s;
}
.SlideShow_Video .show_img .title::before {
    content: attr(data-text);
    position: absolute;
    left: 0px;
    width: 0%;
    height: auto;
    -webkit-text-stroke: 0vw 0px #ffffff;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    animation-duration: 3s;
    color: #ffffff; 
    animation-name: title_slide_before;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    overflow: hidden;    

    text-shadow: #000 1px 1px 2px;
}
.SlideShow_Video .show_img .subtitle{
    font-size: 36px;
    margin-top: 30px;
}
.SlideShow_Video .show_img .subtitle::before {
    color: #ffffff;
    flex-wrap: wrap;
}
@keyframes title_slide_before {
    0%, 20%{
        width: 0%;
        border-right: 2px solid rgb(0 39 104);
    }
    99%{
        border-right: 2px solid rgb(0 39 104);
    }
    100%{
        width: 100%;
    }
}

.SlideShow_Video .show_img{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    min-width: 100%;
    height: 100%;
    position: absolute;
    transition: 1s;
}
.SlideShow_Video .show_img .video_background {
    background: #000;
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: -99;
}
.SlideShow_Video .show_img .video_background video{
    position: absolute;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s;
}
.SlideShow_Video .show_img .video_foreground, .SlideShow_Video .show_img .video_background iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    scale: 1;
}
@media (min-aspect-ratio: 16/9) {
    .SlideShow_Video .show_img .video_foreground { 
        height: 300%; 
        top: -100%; 
    }
}
@media (max-aspect-ratio: 16/9) {
    .SlideShow_Video .show_img .video_foreground { 
        width: 300%; 
        left: -100%; 
    }
}
@media all and (max-width: 1200px) {
    .SlideShow_Video .show_img .video_foreground, .SlideShow_Video .show_img .video_background iframe {
        top: -50px;
        height: 120%;
    }
    .SlideShow_Video .container{
        gap: 20px;
    }
    .SlideShow_Video .show_img .title{
        height: inherit;
        display: flex;
        align-items: flex-end;
    }
    .SlideShow_Video .show_img .title::before{
        white-space: pre-wrap;
        text-align: center;
    }
    @keyframes title_slide_before {
        0%, 20%{
            width: 0%;
            border-right: 2px solid rgb(0 39 104);
        }
        99%{
            border-right: 2px solid rgb(0 39 104);
        }
        100%{
            width: 100%;
        }
    }
    .SlideShow_Video .show_img .subtitle{
        line-height: 36px;
        display: flex;
        align-items: flex-start;
        margin-top: 0;
    }
}
@media all and (max-width: 1010px) {
    .SlideShow_Video .show_img .video_background video{
        width: 120%;
    }
}
@media all and (max-width: 950px) {
    .SlideShow_Video .show_img .video_foreground, .SlideShow_Video .show_img .video_background iframe {
        top: -20px;
        height: 110%;
    }
}
@media all and (max-width: 830px) {
    .SlideShow_Video .show_img .video_background video{
        width: 180%;
    }
}
@media all and (max-width: 600px) {
    .SlideShow_Video .show_img .vid-info { 
        width: 50%; 
        padding: .5rem; 
    }
    .SlideShow_Video .show_img .vid-info h1 { 
        margin-bottom: .2rem; 
    }
}
@media all and (max-width: 580px) {
    .SlideShow_Video .show_img .video_background video{
        width: 240%;
    }    
    .SlideShow_Video .show_img .title {
        font-size: 32px;
        line-height: 30px;
    }
    .SlideShow_Video .show_img .subtitle {
        font-size: 18px;
        line-height: 18px;
    }
}
@media all and (max-width: 500px) {
    .SlideShow_Video .show_img .vid-info .acronym {
        display: none;
    }
}
@media all and (max-width: 460px) {
    .SlideShow_Video .show_img .video_foreground, .SlideShow_Video .show_img .video_background iframe {
        scale: 1.5;
    }
    .SlideShow_Video .show_img .video_foreground, .SlideShow_Video .show_img .video_background iframe{
        top: 72px;
        height: 80%;
    }
}
@media all and (max-width: 430px) {
    .SlideShow_Video .show_img .video_background video{
        width: 280%;
    }
}

@media only screen and (max-width: 1160px) {
    .SlideShow_Video .show_img .title{
        white-space: initial;
    }
    .SlideShow_Video .show_img .title::before{
        display: flex;
    }
}

.SlideShow_Video .show_img .video_background .video{
    z-index: 2;
}
.SlideShow_Video .show_img .show_img_slide{
    width: 100%;
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@supports (-webkit-touch-callout: none) {
    .SlideShow_Video .show_img .video_background .video{
        display: none !important;
    }
}