.page_purchase{

}
.page_purchase .div_border_bottom{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 40px 0px 10px;
    border-bottom: 1px solid #4d5258;
    margin-bottom: 30px;

    font-size: 24px;
}


.page_purchase .div_courses{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    margin-bottom: 30px;
}
.page_purchase .div_courses .inf_course{
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    background: #f5f5f5;

    transition: 1s ease-in-out;
}
.page_purchase .div_courses .inf_course .img_cover{
    width: 200px;
    min-width: 200px;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.page_purchase .div_courses .inf_course .details_course{
    flex-grow: 1;
    height: calc(100% - 40px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    padding: 20px 0px;
    position: relative;
}
.page_purchase .div_courses .inf_course .details_course .name_course{
    width: calc(100% - 20px);
    padding-right: 20px;
    font-family: 'reforma1918_700', sans-serif;
    font-size: 22px;
    line-height: 22px;
    text-transform: uppercase;
}
.page_purchase .div_courses .inf_course .details_course .description_course{
    width: calc(100% - 20px);
    padding-right: 20px;
    overflow: hidden;
    flex-grow: 1;
    font-size: 18px;
    line-height: 20px;
    text-shadow: #ffffff 1px 1px 1px;
    font-family: 'reforma_1918_300', sans-serif;
}
.page_purchase .div_courses .inf_course .details_course .color_text{
    width: 100%;
    height: 40px;
    bottom: 70px;
    position: absolute;
    box-shadow: inset 0px -14px 10px #f5f5f5;
}
.page_purchase .div_courses .inf_course .details_course .div_purchase{
    width: calc(100% - 20px);
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;

    position: relative;
}
.page_purchase .div_courses .inf_course .details_course .div_purchase .div_btn_course{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    flex-grow: 1;
}
.page_purchase .div_courses .inf_course .details_course .div_purchase .div_btn_course .btn_course{
    width: 210px;
    color: #ffffff;
    padding: 10px 0px;
    text-align: center;
    cursor: pointer;
    font-family: 'reforma1918_500', sans-serif;
}
.page_purchase .div_courses .inf_course .details_course .div_purchase .show_price{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-family: 'Inter';
}
.page_purchase .div_courses .inf_course .details_course .div_purchase .show_price span{
    font-size: 18px;
    font-weight: bold;
}
.page_purchase .div_courses .inf_course .details_course .div_purchase .show_price .span_price{
    text-decoration: line-through;
    font-size: 14px;
    font-weight: normal;
}
.page_purchase .div_courses .inf_course .details_course .div_purchase .hide_data{
    display: flex;
}

.page_purchase .div_show_purchase{
    width: 100%;
    padding-bottom: 16px;
    display: flex;
    justify-content: flex-end;
}
.page_purchase .div_show_purchase div{
    font-size: 16px;
}
.page_purchase .div_show_purchase div span{
    font-size: 24px;
    font-weight: bold;
    font-family: 'Inter';
}
.page_purchase .save_purchase{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
    padding: 10px 20px;
    margin-bottom: 40px;
    cursor: pointer;
    color: #ffffff;
    font-family: 'Inter';
}

/* msg */
.page_purchase .show_msg{    
    position: fixed;
    left: 0;
    top: 0;
    background: rgb(0 0 0 / 60%);
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    transition: 1 ease-in-out;
}
.page_purchase .show_msg .ld-ripple {
    position: relative;
    width: 80px;
    height: 80px;
}
.page_purchase .show_msg .ld-ripple div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: ld-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.page_purchase .show_msg .ld-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}
@keyframes ld-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }
    4.9% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }
    5% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}
/* end */

@media only screen and (max-width: 930px) {
    .page_purchase .div_courses .inf_course{
        flex-direction: column;
        height: 380px;
    }
    .page_purchase .div_courses .inf_course .img_cover{
        width: calc(100% - 40px);
        height: 140px;
        min-height: 140px;
        padding: 0 20px;
    }
    .page_purchase .div_courses .inf_course .details_course{
        height: calc(100% - 450px);
        padding-top: 0px;
    }
    .page_purchase .div_courses .inf_course .details_course .name_course,
    .page_purchase .div_courses .inf_course .details_course .description_course,
    .page_purchase .div_courses .inf_course .details_course .color_text,
    .page_purchase .div_courses .inf_course .details_course .div_purchase{
        width: calc(100% - 40px);
        padding: 0 20px;
        justify-content: center;
    }
    .page_purchase .div_courses .inf_course .details_course .color_text{
        bottom: 106px;
    }
    .page_purchase .div_courses .inf_course .details_course .div_purchase .hide_data{
        display: none;
    }
    .page_purchase .div_courses .inf_course .details_course .div_purchase .div_btn_course{
        justify-content: center;
    }
}
@media only screen and (max-width: 580px) {
    .page_purchase .div_search .input_search {
        width: calc(100% - 20px);
    }
}
@media only screen and (max-width: 548px) {
    .page_purchase .div_courses .inf_course .details_course .div_purchase .div_btn_course{
        width: 100%;
        flex-direction: column;
        gap: 5px;
    }
    .page_purchase .div_courses .inf_course .details_course .div_purchase .div_btn_course .btn_course{
        flex-grow: 1;
        width: 100%;
    }
    .page_purchase .div_courses .inf_course{
        height: 444px;
    }
    .page_purchase .div_courses .inf_course .details_course {
        height: calc(100% - 450px);
    }
    .page_purchase .div_courses .inf_course .details_course .color_text {
        bottom: 154px;
    }
}

@media only screen and (max-width: 480px) {
    .page_purchase .div_courses .inf_course .details_course .div_purchase{
        flex-direction: column;
        gap: 10px;
    }
    .page_purchase .div_courses .inf_course .details_course .div_purchase .btn_course{
        width: 100%;
    }
    .page_purchase .div_courses .inf_course{
        height: 400px;
    }
    .page_purchase .div_courses .inf_course .details_course .color_text{
        bottom: 120px;
    }
}