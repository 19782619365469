.PopUp .InfCourse {
    width: 700px;
}

.PopUp .InfCourse .show_video{
    width: 100%;
}
.PopUp .InfCourse .show_video .aspect_video{
    aspect-ratio: 16/9;
    width: -webkit-fill-available;
}
.PopUp .InfCourse .show_video .aspect_video .div_video{
    width: -webkit-fill-available;
}
.PopUp .InfCourse .title{
    width: 100%;
    font-family: 'reforma1918_700', sans-serif;
    font-size: 22px;
    line-height: 22px;
    text-transform: uppercase;
}
.PopUp .InfCourse .text{    
    width: 100%;
    font-size: 14px;
    text-shadow: #ffffff 1px 1px 1px;
    font-family: 'reforma_1918_300', sans-serif;
}
.PopUp .InfCourse .btn_course{
    width: 100%;
    color: #ffffff;
    padding: 10px 0px;
    text-align: center;
    cursor: pointer;
    font-family: 'reforma1918_500', sans-serif;
}

.PopUp .InfCourse .show_price{
    width: 100%;
    font-family: 'Inter';
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;    
}
.PopUp .InfCourse .show_price span{
    font-size: 18px;
    font-weight: bold;
}
.PopUp .InfCourse .show_price .span_price{
    font-size: 14px;
    font-weight: normal;
    text-decoration: line-through;
}

@media only screen and (max-width: 580px) {
    .PopUp .InfCourse {
        width: 90%;
    }
}