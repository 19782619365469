.page_contact {
    display: flex;
    flex-direction: column;
    gap: 80px;
    background-color: #4d5258;
    color: #ffffff;
    padding: 100px 0px;
}
.page_contact .container{
    width: calc(100% - 80px);
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.page_contact .title_page{
    text-align: center;
    font-family: 'reforma1918_700', sans-serif;
    font-size: 32px;
    line-height: 38px;
    text-transform: uppercase;
}

.page_contact .show_data_contact{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    gap: 60px;
}
.page_contact .show_data_contact .div_form{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 30%;
}
.page_contact .show_data_contact .div_form .form{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
}
.page_contact .show_data_contact .div_form .form .wave-group {
    position: relative;
    margin-bottom: 26px;
}
.page_contact .show_data_contact .div_form .form .wave-group .input {
    font-size: 16px;
    padding: 10px;
    display: block;
    width: calc(100% - 22px);
    border: 1px solid #ffffff;
    background: transparent;
    color: #ffffff;
}
.page_contact .show_data_contact .div_form .form .wave-group .textarea{
    resize: none;
    min-height: 80px;
}
.page_contact .show_data_contact .div_form .form .wave-group .input:focus {
    outline: none;
}
.page_contact .show_data_contact .div_form .form .wave-group .label {
    color: #ffffff;
    font-size: 14px;
    line-height: 14px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 12px;
    display: flex
}
.page_contact .show_data_contact .div_form .form .wave-group .label-char {
    transition: 0.2s ease all;
    transition-delay: calc(var(--index) * .05s);
}
.page_contact .show_data_contact .div_form .form .wave-group .input:focus~label .label-char, 
.page_contact .show_data_contact .div_form .form .wave-group .input:valid~label .label-char {
    transform: translateY(-20px);
    font-size: 14px;
    color: #ffffff;
    background-color: #4d5258;
}
.page_contact .show_data_contact .div_form .form .wave-group .label-char:nth-child(1){
    padding-left: 10px;
}
.page_contact .show_data_contact .div_form .form .wave-group .label-char:nth-last-child(-n + 1){
    padding-right: 10px;
}
.page_contact .show_data_contact .div_form .form .wave-group .bar {
    position: relative;
    display: block;
    width: 200px;
}
.page_contact .show_data_contact .div_form .form .wave-group .bar:before {
    left: 50%;
}
.page_contact .show_data_contact .div_form .form .wave-group .bar:after {
    right: 50%;
}
.page_contact .show_data_contact .div_form .form .wave-group .input:focus~.bar:before, 
.page_contact .show_data_contact .div_form .form .wave-group .input:focus~.bar:after {
    width: 50%;
}
.page_contact .show_data_contact .div_form .form .div_btn{
    text-align: right;
}
.page_contact .show_data_contact .div_form .form .div_btn .btn{
    background-color: #3aa856;
    color: #ffffff;
    border: transparent;
    padding: 10px 30px;
    font-family: 'reforma_1918_300', sans-serif;
    font-size: 16px;
    cursor: pointer;
}
.page_contact .show_data_contact .data_text{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 16px;
    width: auto;
}
.page_contact .show_data_contact .data_text .email{
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
}
.page_contact .show_data_contact .data_text .text{
    font-size: 14px;
    text-align: left;
}
.page_contact .show_data_contact .data_text .div_icons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-top: 30px;
}
.page_contact .show_data_contact .data_text .div_icons .img{
    width: 40px;
    height: 40px;
}

@media only screen and (max-width: 1200px) {
    .page_contact .show_data_contact .div_form{
        width: 40%;
    }
    .page_contact .show_data_contact{
        gap: 30px;
    }
}
@media only screen and (max-width: 800px) {
    .page_contact .show_data_contact .div_form{
        flex-grow: 1;
    }
}
@media only screen and (max-width: 680px) {
    .page_contact .show_data_contact{
        flex-direction: column;
    }
    .page_contact .show_data_contact{
        gap: 60px;
    }
    .page_contact .show_data_contact .div_form{
        width: 100%;
    }
    .page_contact .show_data_contact .data_text{
        width: 100%;
        align-items: center;
    }
    .page_contact .show_data_contact .data_text .email,
    .page_contact .show_data_contact .data_text .text,
    .page_contact .show_data_contact .data_text .div_icons{
        width: 100%;
        text-align: center;
    }
}
@media only screen and (max-width: 580px) {
    .page_contact .container {
        padding: 0px 16px;
        width: calc(100% - 32px);
    }
    .page_contact .show_data_contact .div_form .form .div_btn .btn{
        width: 100%;
    }
}