@font-face {
    font-family: 'reforma_1918_300';
    font-style: normal;
    font-weight: 300;
    src: local('reforma_1918_300'), 
        url('https://josemery.fastevolution.com.br/fonts/Reforma1918_300_Blanca.ttf') format('truetype');
}
@font-face {
    font-family: 'reforma1918_500';
    font-style: normal;
    font-weight: 500;
    src: local('reforma1918_500'),
        url('https://josemery.fastevolution.com.br/fonts/Reforma1918_500_Gris.ttf') format('truetype');
}
@font-face {
    font-family: 'reforma1918_700';
    font-style: normal;
    font-weight: 700;
    src: local('reforma1918_700'),
        url('https://josemery.fastevolution.com.br/fonts/Reforma1918_700_Negra.ttf') format('truetype');
}
@font-face {
    font-family: 'renner_400';
    font-style: normal;
    font-weight: 400;
    src: local('renner_400'),
        url('https://josemery.fastevolution.com.br/fonts/Renner_400_Book.ttf') format('truetype');
}

html {
    scroll-behavior: smooth;
}
body, html {
    height: 100%;
}
body {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    color: #474747;
    font-family: 'reforma_1918_300', sans-serif;
    background-color: #fff;
    overflow-x: hidden;
    letter-spacing: 0px;
}
a {
    text-decoration: none;
    cursor: pointer;
    color: inherit;
}
p{
    padding: 0px;
    margin: 0;
}
svg {
    transition: all .2s cubic-bezier(.4, 0, 1, 1) 0s;
    cursor: pointer;
}
#root {
    width: 100%;
    height: 100%;
}
.container{
    max-width: 1200px;
    margin: 0% auto;
    padding: 0px 20px;
}
.icons {
    width: 24px;
    height: 24px;
    display: block;
}
ul {
    list-style: disc !important;
    padding-left: 18px;
    margin: 0px;
    padding: 0px;
}
ul li {
    margin-bottom: 20px;
}
.div_video {
    height: auto;
    width: 100%;
    aspect-ratio: 1.777;
    border: none;
}
::placeholder {
    color: #6c757d;
    font-size: 14px;
}
.title_page{
    font-family: 'Bebas neue';
    font-size: 40px;
    margin-bottom: 16px;
}
#return {
    transition: 1s;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 999;
    display: flex;
}
.return_close {
    opacity: 0;
}
.icon_up{
    width: 50px;
    height: 50px;
}


/* page_content */
.page_content{
    width: 100%;
    height: auto;
}
.page_content .show_page:nth-child(2n) {
    background: #f5f5f5;
}
/* end */


/* scrollbar */
::-webkit-scrollbar {
    height: 6px;
    width: 4px;
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0px 14px #b7b7b7;
}
::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0px 14px #111111;
}
/* end */


/* loading */
.return_data_save{
    position: fixed;
    left: 0;
    top: 0;
    background: #b3b3b36e;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: none;
    align-items: center;
    justify-content: center;
}
.div_loading{
    display: flex;
}
.loading{
    width: 60px;
    height: auto;
}
.return_data_save .dot-spinner {
    --uib-size: 2.8rem;
    --uib-speed: .9s;
    --uib-color: #183153;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: var(--uib-size);
    width: var(--uib-size);
}
.return_data_save .dot-spinner__dot {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
}
.return_data_save .dot-spinner__dot::before {
    content: '';
    height: 20%;
    width: 20%;
    border-radius: 50%;
    background-color: var(--uib-color);
    transform: scale(0);
    opacity: 0.5;
    animation: pulse0112 calc(var(--uib-speed) * 1.111) ease-in-out infinite;
    box-shadow: 0 0 20px rgba(18, 31, 53, 0.3);
}
.return_data_save .dot-spinner__dot:nth-child(2) {
    transform: rotate(45deg);
}
.return_data_save .dot-spinner__dot:nth-child(2)::before {
    animation-delay: calc(var(--uib-speed) * -0.875);
}
.return_data_save .dot-spinner__dot:nth-child(3) {
    transform: rotate(90deg);
}
.return_data_save .dot-spinner__dot:nth-child(3)::before {
    animation-delay: calc(var(--uib-speed) * -0.75);
}
.return_data_save .dot-spinner__dot:nth-child(4) {
    transform: rotate(135deg);
}
.return_data_save .dot-spinner__dot:nth-child(4)::before {
    animation-delay: calc(var(--uib-speed) * -0.625);
}
.return_data_save .dot-spinner__dot:nth-child(5) {
    transform: rotate(180deg);
}
.return_data_save .dot-spinner__dot:nth-child(5)::before {
    animation-delay: calc(var(--uib-speed) * -0.5);
}
.return_data_save .dot-spinner__dot:nth-child(6) {
    transform: rotate(225deg);
}
.return_data_save .dot-spinner__dot:nth-child(6)::before {
    animation-delay: calc(var(--uib-speed) * -0.375);
}
.return_data_save .dot-spinner__dot:nth-child(7) {
    transform: rotate(270deg);
}
.return_data_save .dot-spinner__dot:nth-child(7)::before {
    animation-delay: calc(var(--uib-speed) * -0.25);
}
.return_data_save .dot-spinner__dot:nth-child(8) {
    transform: rotate(315deg);
}
.return_data_save .dot-spinner__dot:nth-child(8)::before {
    animation-delay: calc(var(--uib-speed) * -0.125);
}
@keyframes pulse0112 {
    0%, 100% {
        transform: scale(0);
        opacity: 0.5;
    }
    50% {
        transform: scale(1);
        opacity: 1;
    }
}
/* end */


/* popup */
.PopUp {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 8000;
    position: fixed;
    margin: 0% auto;
    overflow-y: auto;
    background: #FFF;
    overflow-x: hidden;
    background-color: rgba(0, 0, 0, 50%);
    font-family: 'inter';
}
.PopUpCenter{
    display: flex;
    align-items: center;
    justify-content: center;
}
.PopUp .all {
    width: 800px;
    height: auto;
    display: flex;
    text-align: center;
    margin: 2em auto 0;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #ffffff;
    overflow-y: hidden;
    position: relative;
    z-index: 30;
    border: 1px solid #005FA1;
    margin-bottom: 40px;
    box-shadow: 0px 0px 8px 0px rgb(255 255 255 / 50%);
}
.PopUp .close_all{
    position: absolute;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    z-index: 20;
    top: 0;
}
.PopUp .all .div_data {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    padding: 20px;
}
.PopUp .all .type_div{
    display: flex;
    text-align: center;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding-bottom: 6px;
    font-weight: bold;
    border-bottom:2px solid #324d6b;
    font-size: 18px;
    line-height: 18px;
    text-transform: uppercase;
}
.PopUp .all .div_data .content {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}
.PopUp .all .div_data .content .title_stage{
    width: 100%;
    margin-top: 10px;
}
.PopUp .all .div_data .content .opt_select{
    flex-grow: 1;
    width: auto;
    padding: 6px 10px;
    cursor: pointer;
}
.PopUp .all .div_data .content .yes_update {
    background-color: #76c57e;
}
.PopUp .all .div_data .content .not_update {
    background-color: #e95656;
}
/* end */