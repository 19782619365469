.HeaderFixed{
    width: 100%;
    height: 80px;
}
.Header{
    width: 100%;
    height: 80px;
    display: flex;
    font-family: 'renner_400', sans-serif;

    box-shadow: 0 0 6px 0 #000000;
    background: #ffffff;
    
    position: fixed;
    top: 0;
    z-index: 88;
    transition: 0.5s ease-in-out;
}
.div_menu_scroll {
    height: 70px;
    box-shadow: 0px 0px 6px 0px #000000;
}
.Header .container{
    width: calc(100% - 40px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.Header .opt_menu{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.Header .div_logo{
    height: 100%;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
}
.Header .div_logo .logo{
    height: 90%;
    width: auto;
    cursor: pointer;
    transition: 0.5s;
}
.Header .div_menu{
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 40px;
}
.Header .div_menu .show_name_menu{
    line-height: 14px;
    position: relative;
}   
.Header .div_menu .show_name_menu .menu_active{
    width: 100%;
    height: 4px;
    background-color: #2d3c53;
    position: absolute;
    top: 38px;
}
.Header .div_menu .show_name_menu:before{
    content: '';
    width: auto;
    height: 4px;
    background-color: #2d3c53;
    
    position: absolute;
    top: 38px;
    left: 0;
    bottom: -2px;
    scale: 0 1;
    transform-origin: left;
    transition: scale 0.36s;
}
.Header .div_menu .show_name_menu:hover::before{
    width: 100%;
    scale: 1;
}

/* menu mobile */
.Header .div_icon_menu{
    z-index: 88;
}
.Header .div_icon_menu #checkbox {
    display: none;
}
.Header .div_icon_menu .toggle {
    position: relative;
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    transition-duration: .5s;
}
.Header .div_icon_menu .bars {
    width: 100%;
    height: 4px;
    background-color: #2d3c53;
    border-radius: 4px;
}
.Header .div_icon_menu .bars_new_color {
    background-color: #FFFFFF;
}
.Header .div_icon_menu #bar2 {
    transition-duration: .8s;
}
.Header .div_icon_menu #bar1, .Header .div_icon_menu #bar3 {
    width: 70%;
}
.Header .div_icon_menu #checkbox:checked + .toggle .bars {
    position: absolute;
    transition-duration: .5s;
}
.Header .div_icon_menu #checkbox:checked + .toggle #bar2 {
    transform: scaleX(0);
    transition-duration: .5s;
}
.Header .div_icon_menu #checkbox:checked + .toggle #bar1 {
    width: 100%;
    transform: rotate(45deg);
    transition-duration: .5s;
}
.Header .div_icon_menu #checkbox:checked + .toggle #bar3 {
    width: 100%;
    transform: rotate(-45deg);
    transition-duration: .5s;
}
.Header .div_icon_menu #checkbox:checked + .toggle {
    transition-duration: .5s;
    transform: rotate(180deg);
}
/* end */


/* inf user */
.Header .div_inf_user{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
}
.Header .div_inf_user .div_img{
    width: 34px;
    height: 34px;
    display: flex;
}
.Header .div_inf_user .div_img .img_user{
    display: flex;   
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 4px;
}
.Header .div_inf_user .div_img .icon_menu_profile{
    width: 34px;
    height: 34px;
    display: flex;
}
.Header .div_inf_user .div_name_user{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
}
.Header .div_inf_user .div_name_user div:nth-child(1){
    font-size: 12px;
}
.Header .div_inf_user .div_name_user div:nth-child(2){
    font-size: 16px;
    font-weight: 300;
}
.Header .div_menu .inf_login_purchase{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.Header .div_menu .inf_login_purchase .div_purchase{
    position: relative;
}
.Header .div_menu .inf_login_purchase .div_purchase .qtd_course{
    width: 18px;
    height: 18px;
    border-radius: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Inter;
    color: #ffffff;
    font-size: 10px;
    position: absolute;
    bottom: -10px;
    right: 10px;
}
.Header .div_menu .show_name_menu_user{
    line-height: 14px;
    position: relative;
}
.Header .div_menu .show_name_menu_user .menu_active{
    width: 100%;
    height: 4px;
    background-color: #2d3c53;
    position: absolute;
    top: 48px;
}
.Header .div_menu .show_name_menu_user:before{
    content: '';
    width: auto;
    height: 4px;
    background-color: #2d3c53;
    
    position: absolute;
    top: 48px;
    left: 0;
    bottom: -2px;
    scale: 0 1;
    transform-origin: left;
    transition: scale 0.36s;
}
.Header .div_menu .show_name_menu_user:hover::before{
    width: 100%;
    scale: 1;
}
/* end */

@media only screen and (max-width: 1080px) {
    .Header .div_menu{
        gap: 20px;
    }
}
@media only screen and (max-width: 900px) {    
    .Header .buttons__burger {
        display: block;
    }
    .Header .div_menu{
        width: 100%;
        height: 100%;
        position: fixed;
        background: rgb(0 0 0 / 90%);
        right: -100%;
        top: 0;
        transition: 0.5s ease-in-out;

        display: flex;
        flex-direction: column;
        color: #ffffff;
        font-size: 26px;
        justify-content: center;
        gap: 40px;
        z-index: 88;
    }
    .Header .open_menu_mobile{
        animation: open_menu 1s;
        animation-fill-mode: forwards;
    }
    @keyframes open_menu {
        0%{
            right: -100%;
        }
        100%{
            right: 0;
        }
    } 
    .Header .close_menu_mobile{
        animation: close_menu 1s;
        animation-fill-mode: forwards;
    }
    @keyframes close_menu {
        0%{
            right: 0;
        }
        100%{
            right: -100%;
        }
    } 
}