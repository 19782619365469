.TextImg{
    width: 100%;
    height: auto;
    position: relative;
    padding: 80px 0px;
}
.TextImg .bg_image{
    width: 100%;
    height: 100%;

    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    top: 0;

    opacity: 0.6;
    z-index: 0;
}
.TextImg .container{
    position: relative;
    z-index: 1;
}
.TextImg .show_data_text{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 80px;
}

.TextImg .div_text{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-grow: 1;
}
.TextImg .div_text .title{
    font-family: 'reforma1918_700', sans-serif;
    font-size: 32px;
    line-height: 38px;
    text-transform: uppercase;
}
.TextImg .div_text .text{
    font-size: 18px;
    text-shadow: #ffffff 1px 1px 1px;
    font-family: 'reforma_1918_300', sans-serif;
    margin-top: 20px;
}
.TextImg .align_left {
    text-align: left;
    justify-content: flex-start;
    align-items: flex-start;
}
.TextImg .align_center {
    text-align: center;
    justify-content: center;
    align-items: center;
}
.TextImg .align_right{
    text-align: right;
    justify-content: flex-end;
    align-items: flex-end;
}
.TextImg .align_center .title, .TextImg .align_center .text{
    width: 100%;
    text-align: center;
}
.TextImg .div_text .btn{
    width: 50%;
    margin-top: 30px;

    display: flex;
    flex-direction: column;
    gap: 16px;
    font-size: 16px;
    transition: 0.5s ease-in-out;
}
.TextImg .div_text .btn a{
    color: #ffffff;
    background: #3aa856;
    padding: 10px 0px;
    width: 100%;
    text-align: center;
    cursor: pointer;
    font-family: 'reforma1918_500', sans-serif;
}
.TextImg .div_text .btn a:hover{
    background: #76c57e;
}

.TextImg .show_data_text .div_img{
    width: 36%;
    min-width: 36%;
    height: max-content;
    
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.TextImg .show_data_text .align_img{
    width: 44%;
    min-width: 44%;
}
.TextImg .show_data_text .div_img img{
    width: -webkit-fill-available;
    width: -moz-available;
    width: fill-available;         

    height: auto;
    display: flex;
}

@media only screen and (max-width: 990px) {
    .TextImg .show_data_text{
        gap: 30px;
    }
    .TextImg .div_text .btn{
        width: 100%;
    }
}
@media only screen and (max-width: 800px) {
    .TextImg .show_data_text{
        flex-direction: column;
        gap: 50px;
    }

    .TextImg .div_text{
        align-items: center;
        text-align: center;
        order: 1;
    }
    .TextImg .show_data_text .div_img{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        order: 2;
    }
    .TextImg .show_data_text .div_img img{
        width: 60%;
    }
    .TextImg .div_text{
        width: 100%;
    }
    .TextImg .show_data_text .div_img img{
        width: 90%;
    }
}