.page_login{
    width: 100%;
    height: auto;
    padding: 80px 0px;
    background-color: #f3f3f3;
    display: flex;
    justify-content: center;
    align-items: center;
}
.page_login .div_data_login{
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.page_login .div_data_login .show_data_login{
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid #4d5258;
    border-radius: 6px;
    padding: 40px 20px;
    background: #f3f3f3;
    box-shadow: 0 0 16px rgb(0 0 0 / 20%);
}
.page_login .div_data_login .show_data_login .div_text{
    color: #4d5258;
    font-weight: bold;
    padding-bottom: 20px;
}
.page_login .div_data_login .show_data_login .name_input{
    width: 100%;
    font-weight: 500;
    padding-bottom: 4px;
    text-align: left;
}
.page_login .div_data_login .show_data_login .div_input{
    width: 100%;
    margin-top: 0;
    margin-bottom: 20px;
    position: relative;
}
.page_login .div_data_login .show_data_login .error{
    color: #d95c5c;
    text-align: center;
    font-weight: bold;
}
.page_login .div_data_login .show_data_login .div_input .div_eye{
    position: absolute;
    top: 14px;
    right: 10px;
    z-index: 2;
}
.page_login .div_data_login .show_data_login .div_input input{
    width: calc(100% - 32px);
    background: rgba(0, 0, 0, 0);
    border: 1px solid #4d5258;
    border-radius: 6px;
    box-shadow: inset 0 0 10px rgb(0 95 161 / 10%);
    color: #4d5258;
    padding: 16px;
}
.page_login .div_data_login .show_data_login .div_input input::placeholder {
    color: #4d5258;
    opacity: 0.4;
}
.page_login .div_data_login .show_data_login .type_save{
    color: #4d5258;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    transition: 1s ease-in-out;
}
.page_login .div_data_login .show_data_login .type_save .div_save{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
}
.page_login .div_data_login .show_data_login .type_save .div_save .input_checkbox{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.page_login .div_data_login .show_data_login .type_save .div_save .input_checkbox .checkbox{
    width: 16px;
    height: 16px;
}
.page_login .div_data_login .show_data_login .type_save .div_recover{
    width: 100%;
    cursor: pointer;
    font-weight: bold;
    margin-top: 16px;
    text-align: center;
}
.page_login .div_data_login .show_data_login .type_save .register{
    width: auto;
    margin-top: 0;
    text-align: left;
}
.page_login .div_data_login .show_data_login .type_save .div_btn{
    width: 122px;
    height: 40px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 10px;
    color: #ffffff;
    background-color: #4d5258;

    cursor: pointer;
    border: none;
    font-size: 14px;
}

.page_login .div_img_bg{
    width: auto;
    height: calc(100% - 40px);
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.page_login .div_img_bg .img_bg_login{
    display: flex;
    width: auto;
    height: 100%;
}

@media only screen and (max-width: 480px) {
    .page_login .div_data_login .show_data_login {
        width: 80%;
    }
}
@media only screen and (max-width: 350px) {
    .page_login .div_data_login .show_data_login .type_save{
        flex-direction: column;
        gap: 10px;
    }
    .page_login .div_data_login .show_data_login .type_save .div_btn{
        order: 1;
        width: 100%;
        justify-content: space-between;
        padding: 0px 10px;
    }
    .page_login .div_data_login .show_data_login .type_save .div_recover{
        order: 2;
        width: 100%;
        text-align: right;
    }
}