.page_register{
    width: 100%;
    height: auto;
    padding: 40px 0px 80px;
}
.page_register .title{
    width: calc(100% - 20px);
    padding-right: 20px;
    font-family: 'reforma1918_700', sans-serif;
    font-size: 22px;
    line-height: 22px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 20px;
}
.page_register .div_inf_user{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    margin-bottom: 30px;
    
    border: 1px solid #4d5258;
    border-radius: 6px;
    padding: 20px;
}
.page_register .div_inf_user .div_img_user{
    width: 200px;
    height: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}  
.page_register .div_inf_user .div_img_user .btn_show_img{
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 4px;
    display: flex;
}
.page_register .div_inf_user .div_img_user .btn_user{
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 4px;
    cursor: pointer;
}
.page_register .div_inf_user .div_img_user .btn_user input{
    display: none;
}
.page_register .div_inf_user .div_show_inf{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    flex-grow: 1;
    gap: 20px;
}
.page_register .div_inf_user .div_show_inf .btn_save{
    width: 100%;
    padding: 10px;
    border: none;
    color: #ffffff;
    font-family: 'reforma_1918_300', sans-serif;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
}
.page_register .div_inf_user .div_show_inf .type_data{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
}
.page_register .div_inf_user .div_show_inf .type_data .name_type_data{
    width: calc(100% - 40px);
    padding: 10px 20px;
    background-color: #2d3c53;
    color: #ffffff;
}
.page_register .div_inf_user .div_show_inf .type_data .show_inf_input{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
}
.page_register .div_inf_user .div_show_inf .type_data .show_inf_input .div_input{
    position: relative;
    margin-top: 8px;
}
.page_register .div_inf_user .div_show_inf .type_data .show_inf_input .space_div{
    flex-grow: 1;
}
.page_register .div_inf_user .div_show_inf .type_data .show_inf_input .div_input input{
    width: calc(100% - 22px);
    font-size: 16px;
    padding: 10px;
    display: block;
    border: 1px solid #474747;
    background: transparent;
}
.page_register .div_inf_user .div_show_inf .type_data .show_inf_input .div_input .pass{
    width: 100px;
}
.page_register .div_inf_user .div_show_inf .type_data .show_inf_input .div_input .cep{
    text-align: center;
    width: 80px;
}
.page_register .div_inf_user .div_show_inf .type_data .show_inf_input .div_input .cpf{
    text-align: center;
    width: 120px;
}
.page_register .div_inf_user .div_show_inf .type_data .show_inf_input .div_input  .phone{
    text-align: center;
    width: 130px;
}
.page_register .div_inf_user .div_show_inf .type_data .show_inf_input .div_input .uf{
    text-align: center;
    text-transform: uppercase;
    width: 40px;
}
.page_register .div_inf_user .div_show_inf .type_data .show_inf_input .div_input .number{
    text-align: center;
    width: 80px;
}
.page_register .div_inf_user .div_show_inf .type_data .show_inf_input .div_input input:focus {
    outline: none;
}
.page_register .div_inf_user .div_show_inf .type_data .show_inf_input .div_input .name_input{
    position: absolute;
    top: -12px;
    left: 8px;
    padding: 0px 6px;
    background: #ffffff;
}