.page_faq{
    width: 100%;
    padding: 80px 0px;
    position: relative;
}
.page_faq .container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.page_faq .div_faq{
    width: 100%;
}
.page_faq .div_faq .title{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #2d3c53;
    color: #ffffff;
    padding: 10px;
    cursor: pointer;
}
.page_faq .div_faq .title .icons{
    transition: 0.5s;
}
.page_faq .div_faq .title .active{
    transform: rotate(90deg);
}
.page_faq .div_faq .text{
    max-height: 0;
    overflow-y: hidden;
    font-size: 14px;

    transition: max-height .5s ease-out;
}