.page_logado{
    width: 100%;
    height: auto;
}
.page_logado .container{
    width: calc(100% - 40px);
}
.page_logado .div_inf_bg{
    background-color: #f3f3f3;
    width: 100%;
}
.page_logado .div_menu_opt_user{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 40px 0px 14px;
    margin-bottom: 30px;
    gap: 40px;
}
.page_logado .div_menu_opt_user .div_opt{
    width: 90px;
    text-align: center;
    position: relative;
    cursor: pointer;
}
.page_logado .div_menu_opt_user .div_opt_{
    width: auto;
}
.page_logado .div_menu_opt_user .div_opt:before{
    content: '';
    width: auto;
    height: 4px;
    background-color: #2d3c53;
    
    position: absolute;
    top: 26px;
    left: 0;
    bottom: -2px;
    scale: 0 1;
    transform-origin: left;
    transition: scale 0.36s;
}
.page_logado .div_menu_opt_user .div_opt:hover::before{
    width: 100%;
    scale: 1;
}
.page_logado .div_menu_opt_user .div_opt .opt_active{
    width: 100%;
    height: 4px;
    background-color: #2d3c53;
    position: absolute;
    top: 26px;
}

.page_logado .div_show_inf_user{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
}
.page_logado .div_show_inf_user .div_inf_user{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    margin-bottom: 30px;
    
    border: 1px solid #4d5258;
    border-radius: 6px;
    padding: 20px;
}
.page_logado .div_show_inf_user .div_inf_user .div_img_user{
    width: 200px;
    height: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}  
.page_logado .div_show_inf_user .div_inf_user .div_img_user .btn_show_img{
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 4px;
    display: flex;
}
.page_logado .div_show_inf_user .div_inf_user .div_img_user .btn_user{
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 4px;
    cursor: pointer;
}
.page_logado .div_show_inf_user .div_inf_user .div_img_user .btn_user input{
    display: none;
}
.page_logado .div_show_inf_user .div_inf_user .div_show_inf{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    flex-grow: 1;
    gap: 20px;
}
.page_logado .div_show_inf_user .div_inf_user .div_show_inf .btn_save{
    width: 100%;
    padding: 10px;
    border: none;
    color: #ffffff;
    font-family: 'reforma_1918_300', sans-serif;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
}
.page_logado .div_show_inf_user .div_inf_user .div_show_inf .type_data{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
}
.page_logado .div_show_inf_user .div_inf_user .div_show_inf .type_data .name_type_data{
    width: calc(100% - 40px);
    padding: 10px 20px;
    background-color: #2d3c53;
    color: #ffffff;
}
.page_logado .div_show_inf_user .div_inf_user .div_show_inf .type_data .show_inf_input{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
}
.page_logado .div_show_inf_user .div_inf_user .div_show_inf .type_data .show_inf_input .div_input{
    position: relative;
    margin-top: 8px;
}
.page_logado .div_show_inf_user .div_inf_user .div_show_inf .type_data .show_inf_input .space_div{
    flex-grow: 1;
}
.page_logado .div_show_inf_user .div_inf_user .div_show_inf .type_data .show_inf_input .div_input input{
    width: calc(100% - 22px);
    font-size: 16px;
    padding: 10px;
    display: block;
    border: 1px solid #474747;
    background: transparent;
}
.page_logado .div_show_inf_user .div_inf_user .div_show_inf .type_data .show_inf_input .div_input .pass{
    width: 100px;
}
.page_logado .div_show_inf_user .div_inf_user .div_show_inf .type_data .show_inf_input .div_input .cep{
    text-align: center;
    width: 80px;
}
.page_logado .div_show_inf_user .div_inf_user .div_show_inf .type_data .show_inf_input .div_input .cpf{
    text-align: center;
    width: 120px;
}
.page_logado .div_show_inf_user .div_inf_user .div_show_inf .type_data .show_inf_input .div_input .phone{
    text-align: center;
    width: 130px;
}
.page_logado .div_show_inf_user .div_inf_user .div_show_inf .type_data .show_inf_input .div_input .uf{
    text-align: center;
    text-transform: uppercase;
    width: 40px;
}
.page_logado .div_show_inf_user .div_inf_user .div_show_inf .type_data .show_inf_input .div_input .number{
    text-align: center;
    width: 80px;
}
.page_logado .div_show_inf_user .div_inf_user .div_show_inf .type_data .show_inf_input .div_input input:focus {
    outline: none;
}
.page_logado .div_show_inf_user .div_inf_user .div_show_inf .type_data .show_inf_input .div_input .name_input{
    position: absolute;
    top: -12px;
    left: 8px;
    padding: 0px 6px;
    background: #ffffff;
}

.page_logado .list_courses{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 30px;
    margin-bottom: 30px;
}
.page_logado .list_courses .no_data{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}
.page_logado .list_courses .inf_course{    
    width: 320px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    background: #f5f5f5;
    padding: 20px;

    transition: 1s ease-in-out;
}
.page_logado .list_courses .inf_course .div_img{
    width: 100%;
    min-width: 100%;
    height: 140px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.page_logado .list_courses .inf_course .name_course{
    width: calc(100% - 20px);
    padding-right: 20px;
    font-family: 'reforma1918_700', sans-serif;
    font-size: 22px;
    line-height: 22px;
    text-transform: uppercase;
}
.page_logado .list_courses .inf_course .btn{
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 4px;
    cursor: pointer;
}

@media only screen and (max-width: 970px) {
    .page_logado .div_show_inf_user .div_inf_user{
        flex-direction: column;
    }
    .page_logado .div_show_inf_user .div_inf_user .div_show_inf{
        order: 1;
    }
    .page_logado .div_show_inf_user .div_inf_user .div_img_user{
        order: 2;
        width: 100%;
        height: 180px;
    }
    .page_logado .div_show_inf_user .div_inf_user .div_img_user .btn_user{
        min-height: 40px;
    }
}
@media only screen and (max-width: 480px) {
    .page_logado .div_menu_opt_user{
        gap: 20px;
    }
    .page_logado .div_menu_opt_user .div_opt{
        width: auto;
        flex-grow: 1;
    }
    .page_logado .div_show_inf_user .div_inf_user .div_show_inf .type_data .show_inf_input:nth-child(n+1){
        margin-top: 10px;
    }
    .page_logado .div_show_inf_user .div_inf_user .div_show_inf .type_data .show_inf_input .div_input{
        flex-grow: 1;
    }
    .page_logado .div_show_inf_user .div_inf_user .div_show_inf .type_data .show_inf_input .div_input input{
        width: calc(100% - 22px) !important;
    }
}