.page_courses_details {
    width: 100%;
    height: auto;
    padding: 60px 0px 0px;
    
    background: rgb(45 60 83 / 20%);
}
.page_courses_details .title_course{
    width: calc(100% - 20px);
    padding-right: 20px;
    font-family: 'reforma1918_700', sans-serif;
    font-size: 16px;
    line-height: 16px;
    text-transform: uppercase;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 20px;
}
.page_courses_details .title_course .return{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    font-size: 12px;
    line-height: 12px;
}
.page_courses_details .title_course .return .icons{
    transform: rotate(180deg);
    width: 16px;
    height: 16px;
}
.page_courses_details .inf_details{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
    margin-top: 20px;
}
.page_courses_details .inf_details .show_course{
    flex-grow: 1;
}
.page_courses_details .inf_details .show_course .aspect_video{
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
    margin-bottom: 20px;
}
.page_courses_details .inf_details .show_course .aspect_video .div_video {
    width: 100%;
    height: 100%;
}
.page_courses_details .inf_details .show_course .aspect_video .div_video iframe{
    width: 100%;
    height: 100%;
}

.page_courses_details .inf_details .list_module{
    width: 260px;
    min-width: 260px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
}
.page_courses_details .inf_details .list_module .show_module{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}
.page_courses_details .inf_details .list_module .show_module .module{
    width: calc(100% - 22px);
    padding: 10px 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #2d3c53;
    color: #ffffff;
    cursor: pointer;
}
.page_courses_details .inf_details .list_module .show_module .div_list_classes{
    width: calc(100% - 14px);

    max-height: 0;
    overflow-y: hidden;
    font-size: 14px;
    color: #3c3c3c;

    transition: max-height .2s ease-out;
    background-color: #ececec;

    z-index: 8;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
}
.page_courses_details .inf_details .list_module .show_module .div_list_classes .show_inf_course{
    width: calc(100% - 8px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    padding: 10px 4px;
}
.page_courses_details .inf_details .list_module .show_module .div_list_classes .show_inf_course:hover{
    background-color: #dddddd;
}
.page_courses_details .inf_details .list_module .show_module .div_list_classes .course_id{
    background-color: #c3c3c3;
}
.page_courses_details .inf_details .list_module .show_module .div_list_classes .course_id:hover{
    background-color: #c3c3c3;
}
.page_courses_details .inf_details .list_module .show_module .div_list_classes .show_inf_course .input_checkbox{
    display: flex;
    width: 16px;
    height: 16px;
}
.page_courses_details .inf_details .list_module .show_module .div_list_classes .show_inf_course .title{
    flex-grow: 1;
    cursor: default;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
}



.page_courses_details .show_details_course{
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 30px;
    margin: 10px 0px;
}
.page_courses_details .show_details_course .details_text{
    width: auto;
    position: relative;
    cursor: pointer;
}
.page_courses_details .show_details_course .details_text:before{
    content: '';
    width: auto;
    height: 4px;
    background-color: #2d3c53;
    
    position: absolute;
    top: 26px;
    left: 0;
    bottom: -2px;
    scale: 0 1;
    transform-origin: left;
    transition: scale 0.36s;
}
.page_courses_details .show_details_course .details_text:hover::before{
    width: 100%;
    scale: 1;
}
.page_courses_details .show_details_course .details_text .opt_active{
    width: 100%;
    height: 4px;
    background-color: #2d3c53;
    position: absolute;
    top: 26px;
}
.page_courses_details .show_details_course .div_text{
    margin-top: 14px;
    font-size: 18px;
}
.page_courses_details .show_details_course .list_download{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    flex-wrap: wrap;
    gap: 20px;
    
    margin-top: 14px;
}
.page_courses_details .show_details_course .list_download .show_download{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 260px;
    padding: 10px;
    border: 2px solid #2d3c53;
}

.page_courses_details .div_page_msg_course{    
    display: flex;
    flex-direction: column;
    gap: 80px;
    background-color: #4d5258;
    color: #ffffff;
    padding: 100px 0px;
}
.page_courses_details .div_page_msg_course .container{
    width: calc(100% - 40px);
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.page_courses_details .div_page_msg_course .title_page{
    text-align: center;
    font-family: 'reforma1918_700', sans-serif;
    font-size: 32px;
    line-height: 38px;
    text-transform: uppercase;
}

.page_courses_details .div_page_msg_course .show_data_contact{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    gap: 60px;
}
.page_courses_details .div_page_msg_course .show_data_contact .div_form{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
}
.page_courses_details .div_page_msg_course .show_data_contact .div_form .form{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
}
.page_courses_details .div_page_msg_course .show_data_contact .div_form .form .wave-group {
    position: relative;
    margin-bottom: 26px;
}
.page_courses_details .div_page_msg_course .show_data_contact .div_form .form .wave-group .input {
    font-size: 16px;
    padding: 10px;
    display: block;
    width: calc(100% - 22px);
    border: 1px solid #ffffff;
    background: transparent;
    color: #ffffff;
}
.page_courses_details .div_page_msg_course .show_data_contact .div_form .form .wave-group .textarea{
    resize: none;
    min-height: 80px;
}
.page_courses_details .div_page_msg_course .show_data_contact .div_form .form .wave-group .input:focus {
    outline: none;
}
.page_courses_details .div_page_msg_course .show_data_contact .div_form .form .wave-group .label {
    color: #ffffff;
    font-size: 14px;
    line-height: 14px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 12px;
    display: flex
}
.page_courses_details .div_page_msg_course .show_data_contact .div_form .form .wave-group .label-char {
    transition: 0.2s ease all;
    transition-delay: calc(var(--index) * .05s);
}
.page_courses_details .div_page_msg_course .show_data_contact .div_form .form .wave-group .input:focus~label .label-char, 
.page_courses_details .div_page_msg_course .show_data_contact .div_form .form .wave-group .input:valid~label .label-char {
    transform: translateY(-20px);
    font-size: 14px;
    color: #ffffff;
    background-color: #4d5258;
}
.page_courses_details .div_page_msg_course .show_data_contact .div_form .form .wave-group .label-char:nth-child(1){
    padding-left: 10px;
}
.page_courses_details .div_page_msg_course .show_data_contact .div_form .form .wave-group .label-char:nth-last-child(-n + 1){
    padding-right: 10px;
}
.page_courses_details .div_page_msg_course .show_data_contact .div_form .form .wave-group .bar {
    position: relative;
    display: block;
    width: 200px;
}
.page_courses_details .div_page_msg_course .show_data_contact .div_form .form .wave-group .bar:before {
    left: 50%;
}
.page_courses_details .div_page_msg_course .show_data_contact .div_form .form .wave-group .bar:after {
    right: 50%;
}
.page_courses_details .div_page_msg_course .show_data_contact .div_form .form .wave-group .input:focus~.bar:before, 
.page_courses_details .div_page_msg_course .show_data_contact .div_form .form .wave-group .input:focus~.bar:after {
    width: 50%;
}
.page_courses_details .div_page_msg_course .show_data_contact .div_form .form .div_btn{
    text-align: right;
}
.page_courses_details .div_page_msg_course .show_data_contact .div_form .form .div_btn .btn{
    background-color: #3aa856;
    color: #ffffff;
    border: transparent;
    padding: 10px 30px;
    font-family: 'reforma_1918_300', sans-serif;
    font-size: 16px;
    cursor: pointer;
}
.page_courses_details .div_page_msg_course .show_data_contact .data_text{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 16px;
    width: auto;
}
.page_courses_details .div_page_msg_course .show_data_contact .data_text .email{
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
}
.page_courses_details .div_page_msg_course .show_data_contact .data_text .text{
    font-size: 14px;
    text-align: left;
}
.page_courses_details .div_page_msg_course .show_data_contact .data_text .div_icons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-top: 30px;
}
.page_courses_details .div_page_msg_course .show_data_contact .data_text .div_icons .img{
    width: 40px;
    height: 40px;
}

@media only screen and (max-width: 1200px) {
    .page_courses_details .div_page_msg_course .show_data_contact{
        gap: 30px;
    }
}
@media only screen and (max-width: 900px) {
    .page_courses_details .inf_details{
        flex-direction: column;
        gap: 4px;
        margin-bottom: 30px;
    }
    .page_courses_details .inf_details .show_course{
        width: 100%;
    }
    .page_courses_details .inf_details .list_module{
        width: 100%;
    }
}
@media only screen and (max-width: 800px) {
}
@media only screen and (max-width: 680px) {
    .page_courses_details .div_page_msg_course .show_data_contact{
        flex-direction: column;
    }
    .page_courses_details .div_page_msg_course .show_data_contact{
        gap: 60px;
    }
    .page_courses_details .div_page_msg_course .show_data_contact .data_text{
        width: 100%;
        align-items: center;
    }
    .page_courses_details .div_page_msg_course .show_data_contact .data_text .email,
    .page_courses_details .div_page_msg_course .show_data_contact .data_text .text,
    .page_courses_details .div_page_msg_course .show_data_contact .data_text .div_icons{
        width: 100%;
        text-align: center;
    }
}
@media only screen and (max-width: 580px) {
    .page_courses_details .div_page_msg_course .container {
        padding: 0px 16px;
        width: calc(100% - 32px);
    }
    .page_courses_details .div_page_msg_course .show_data_contact .div_form .form .div_btn .btn{
        width: 100%;
    }
    .page_courses_details .title_course, .page_courses_details .title_course .return{
        font-size: 12px;
        line-height: 12px;
        gap: 4px;
    }
}