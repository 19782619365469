.PopUp .CheckedLogin{
}

.PopUp .CheckedLogin .div_opt_access{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 20px 0px;
    gap: 20px;
}
.PopUp .CheckedLogin .div_opt_access .div_btn{
    width: 50%;
    flex-grow: 1;
    cursor: pointer;
    padding: 10px 20px;
    color: #2d3c53;
    position: relative;

}

/* login */
.PopUp .CheckedLogin .page_login{
    padding: 0;
    background: initial;
}
.PopUp .CheckedLogin .div_opt_access .line_bottom{
    border-bottom: 4px solid #2d3c53;
}
/* end */


/* register */
.PopUp .CheckedLogin .page_register{
    padding: 0;
}
.PopUp .CheckedLogin .page_register .title{
    display: none;
}
.PopUp .CheckedLogin .page_register .div_inf_user{
    margin: 0;
}
.PopUp .CheckedLogin .page_register .div_inf_user .div_img_user{
    display: none;
}
/* end */

/* msg */
.PopUp .CheckedLogin .show_msg{    
    position: fixed;
    left: 0;
    top: 0;
    background: rgb(0 0 0 / 60%);
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    transition: 1 ease-in-out;
}
.PopUp .CheckedLogin .show_msg .ld-ripple {
    position: relative;
    width: 80px;
    height: 80px;
}
.PopUp .CheckedLogin .show_msg .ld-ripple div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    animation: ld-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.PopUp .CheckedLogin .show_msg .ld-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}
@keyframes ld-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }
    4.9% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 0;
    }
    5% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}
/* end */

@media only screen and (max-width: 570px) {
    .PopUp .CheckedLogin {
        width: 90%;
    }
}