footer{
    width: 100%;
    height: 40px;
    background: rgb(219 219 219);
    font-size: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: 'Poppins', sans-serif;
}