.SlideShow_Img {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: relative;
}
.SlideShow_Img .each-slide-effect{
    position: relative;
}

.SlideShow_Img .show_text{
    width: max-content;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.SlideShow_Img .each-slide-effect .title{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'reforma1918_700', sans-serif;
    font-size: 52px;
    line-height: 46px;
    font-weight: 600;

    position: absolute;
    color: #ffffff; 
    text-shadow: #000 1px 1px 2px;
    text-transform: uppercase;
    white-space: nowrap;
    transition: 0.5s;
}

.SlideShow_Img .div_temp{
    position: absolute;
    right: 30px;
    top: 4px;
    background: #8fd437;
    color: #ffffff;
    padding: 6px 16px;
    border-radius: 6px;
    cursor: pointer;
}

.SlideShow_Img .react-slideshow-container{
    /* max-width: 1200px; */
    margin: 0% auto;
}

.SlideShow_Img .each-slide-effect > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 500px;
}
.SlideShow_Img .each-slide-effect span {
    padding: 20px;
    font-size: 20px;
    background: #efefef;
    text-align: center;
}
.SlideShow_Img .react-slideshow-container .nav:first-of-type{
    left: 60px;
}
.SlideShow_Img .react-slideshow-container .nav:last-of-type{
    right: 60px;
}
.SlideShow_Img .react-slideshow-wrapper .slide{
    border-radius: 20px;
}


@media all and (max-width: 1200px) {
    .SlideShow_Img .container{
        gap: 20px;
    }
    .SlideShow_Img .show_img .title{
        height: inherit;
        display: flex;
        align-items: flex-end;
    }
    .SlideShow_Img .show_img .subtitle{
        line-height: 36px;
        display: flex;
        align-items: flex-start;
        margin-top: 0;
    }
}
@media all and (max-width: 580px) {
    .SlideShow_Img .show_img .title {
        font-size: 32px;
        line-height: 30px;
    }
    .SlideShow_Img .show_img .subtitle {
        font-size: 18px;
        line-height: 18px;
    }
}
@media only screen and (max-width: 1160px) {
    .SlideShow_Img .show_img .title{
        white-space: initial;
    }
}