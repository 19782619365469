.PopUp .ReturnResponse{
    width: 400px;
}
.PopUp .ReturnResponse .icons_return{
    width: 200px;
    height: 200px;
    cursor: default;
}
.PopUp .ReturnResponse .title_return{
    font-size: 16px;
    font-family: 'Inter';
}
.PopUp .ReturnResponse .close_return{
    background-color: #2d3c53;
    color: #ffffff;
    width: 100%;
    padding: 10px 0px;
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'inter';
    cursor: pointer;
    margin-top: 20px;
    border-radius: 6px;
}


@media only screen and (max-width: 580px) {
    .PopUp .ReturnResponse{
        width: 90%;
    }
}